<template>
    <div>
        <div v-if="!sent" class="flex items-center flex-col p-4">
            <img src="/images/missyrockz_logo.png" alt="Logo" class="w-24 mb-4">

            <h1 class="text-missyPink text-5xl md:text-6xl mb-10 text-bodoni-moda">DEIN BIRTHDAY GOODIE</h1>

            <img src="/images/rockz_birthday_header.png" class="mb-8 md:w-2/4" alt="Gewinnspiel">

            <img src="/images/octicons_heart.png" class="w-10 mb-8" alt="Heart">

            <p v-if="$route.params.verify && verified" class="text-2xl text-roboto w-full md:w-1/2 text-center">
                ROCKZ! Du hast dich erfolgreich registriert. Freue dich nun jedes Jahr auf eine kleine EIP Surprise. Wir
                hören uns an deinem Geburtstag.;)
                <br><br>
                Your MISSY 💋
            </p>

            <p v-if="verifiedFailed" class="text-2xl text-roboto w-full md:w-1/2 text-center">
                Oh no! Leider ist der Link zur Verifizierung abgelaufen :(
                Aber hey, MISSY gibt dir eine zweite Chance, es erneut zu versuchen. Klicke dazu einfach auf folgenden
                Link:
                Ready, steady, ROCKZ!
                <br><br>
                <router-link to="/birthday"
                             class="birthday-button text-white px-16 py-2 text-roboto text-sm mb-20">
                    REGISTRIERE DICH NEU
                </router-link>
            </p>

            <p v-if="exists" class="text-2xl text-roboto w-full md:w-1/2 text-center">
                Du hast dich bereits registriert. Jedes Jahr aufs Neue erwartet dich zu deinem Geburtstag ein kleines
                EIP Goody!
            </p>

            <div v-if="!$route.params.verify && !exists" class="flex items-center flex-col">
                <p class="text-lg text-roboto text-center w-60 md:w-96">
                    <strong>Jedes Jahr aufs Neue erwartet dich zu deinem Geburtstag ein kleines EIP Goody!</strong>
                </p>

                <p class="text-roboto-thin mb-16">Lass dich überraschen!</p>

                <p class="text-roboto-thin mb-8">Jetzt schnell ausfüllen:</p>

                <form method="post" @submit.prevent="addBirthday"
                      class="flex justify-center items-center flex-col w-full md:w-2/4">
                    <div class="mb-4 w-full px-4 md:w-96">
                        <label>
                            <input name="name"
                                   type="text"
                                   class="w-full bg-inputBg text-roboto outline-none p-4 rounded-md text-center"
                                   placeholder="Dein Vorname"
                                   v-model="form.first_name"
                                   required>
                        </label>
                        <small v-if="errors && errors.first_name" class="text-red-500 text-sm">{{
                                errors.first_name[0]
                            }}</small>
                    </div>

                    <div class="mb-4 w-full px-4 md:w-96">
                        <label>
                            <input name="name"
                                   type="text"
                                   class="w-full bg-inputBg text-roboto outline-none p-4 rounded-md text-center"
                                   placeholder="Dein Nachname"
                                   v-model="form.last_name"
                                   required>
                        </label>
                        <small v-if="errors && errors.last_name" class="text-red-500 text-sm">{{
                                errors.last_name[0]
                            }}</small>
                    </div>

                    <div class="mb-4 w-full px-4 md:w-96">
                        <label>
                            <input name="email"
                                   type="email"
                                   class="w-full bg-inputBg text-roboto outline-none p-4 rounded-md text-center"
                                   placeholder="Deine E-Mail Adresse"
                                   v-model="form.email"
                                   required>
                        </label>
                        <small v-if="errors && errors.email" class="text-red-500 text-sm">{{ errors.email[0] }}</small>
                    </div>

                    <div class="mb-20 w-full px-4 md:w-96">
                        <label>
                            <input name="birthday"
                                   type="tel"
                                   v-mask="'##/##/####'"
                                   class="w-full bg-inputBg text-roboto outline-none p-4 rounded-md text-center"
                                   placeholder="Dein Geburtsdatum"
                                   onfocus="(this.placeholder='Dein Geburtsdatum')"
                                   v-model="form.birthdate"
                                   @input="validateBirthdate"
                                   required>
                        </label>
                        <small v-if="errors && errors.birthdate" class="text-red-500 text-sm">{{
                                errors.birthdate
                            }}</small>
                    </div>

                    <div class="mb-8 w-full px-4">

                        <label class="block left-label text-roboto-thin">
                            <input type="checkbox" name="data_protection" v-model="form.data_protection">
                            Hiermit erklärst du dich als Nutzer damit einverstanden, dass deine persönlichen Daten von
                            uns als Betreiber in elektronischer Form gespeichert werden – siehe
                            <router-link :to="{name: 'privacy'}" class="text-missyPink">Datenschutzerklärung
                            </router-link>
                            . Du stimmst zu, dass die im Rahmen der Registrierung erhobenen Daten und die weiteren von
                            dir eingegebenen Daten in einer Datenbank gespeichert werden – siehe
                            <router-link :to="{name: 'privacy'}" class="text-missyPink">Datenschutzerklärung
                            </router-link>
                            . Es erfolgt keine Datenspeicherung und Datennutzung ohne ein an anderer Stelle von dir
                            ausdrücklich eingeholtes Einverständnis.
                        </label>

                    </div>

                    <div class="flex">
                        <button type="submit"
                                v-if="!loading"
                                :class="{'button-not': !form.data_protection || errors}"
                                class="birthday-button text-white px-16 py-2 text-roboto text-sm mb-20"
                                :disabled="!form.data_protection">
                            ABSENDEN
                        </button>

                        <div v-else class="lds-facebook">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div v-else
             class="flex items-center flex-col p-4">
            <div
                class="p-4 shadow-2xl bg-white w-96 mb-36">
                <div class="flex items-center flex-col border border-gray-400 text-center p-10">
                    <img src="/images/octicons_heart.png" class="mb-8 w-6" alt="Heart">
                    <h3 class="text-8xl text-missyPink mb-8">YES!</h3>
                    <p class="text-roboto-thin w-44">You can really ROCKZ!<br>Nur noch deine Email Adresse bestätigen
                        und fertig.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Campaign",
    props: {
        campaign: Object,
        verified: {
            type: Boolean,
            default: false,
        },
        verifiedFailed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                first_name: null,
                last_name: null,
                email: null,
                birthdate: null,
                data_protection: false,
                slug: this.$route.params.campaign
            },
            sent: false,
            errors: false,
            error: false,
            exists: false,
            loading: null,
        }
    },
    methods: {
        addBirthday() {
            this.loading = true

            window.axios.post(`/campaigns/subscribers/subscribe`, this.form)
                .then(response => {
                    this.sent = response.data
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error.response)
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors
                    } else if (error.response.status === 409) {
                        this.exists = true
                    }
                })
        },

        validateBirthdate() {
            let current_year = new Date().getFullYear()
            let birthdate = this.form.birthdate.split('/')
            let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

            if (Boolean(birthdate[2]) === false
                || birthdate[0].length !== 2
                || !(birthdate[1] > 0 && birthdate[1] < 13)
                || birthdate[0] < 0 || birthdate[0] > monthLength[birthdate[1] - 1]
                || birthdate[1].length !== 2
                || birthdate[2].length !== 4
                || birthdate[2] > current_year
            ) {
                this.errors = {
                    birthdate: 'Du hast ein falschen Geburtstagsdatum eingegeben. Bitte gebe dein Geburtsdatum wie folgt ein: (dd/mm/yyyy - 01/01/2021).'
                }
                this.error = true
            } else {
                this.errors = null
                this.error = false
            }

            // TODO: Check if condition on button and loading
        },
    },
}
</script>

<style scoped>

</style>