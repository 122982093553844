<template>
    <div class="flex justify-center items-center flex-col w-screen" v-if="campaign">
        <birthday :campaign="campaign"
                  v-if="$route.params.campaign === 'birthday'"
                  :verified="verified"
                  :verified-failed="verifiedFailed"
        ></birthday>
        <heelz-contest :campaign="campaign"
                       v-if="$route.params.campaign === 'heel-z-for-free'"
                       :verified="verified"
                       :verified-failed="verifiedFailed"
        ></heelz-contest>
    </div>

    <mr-footer />
</template>

<script>
import MrFooter from "../components/layout/MrFooter"
import Birthday from "../components/campaigns/Birthday"
import HeelzContest from "../components/campaigns/HeelzContest"

export default {
    name: "Campaign",
    components: {HeelzContest, Birthday, MrFooter},
    data() {
        return {
            form: {
                first_name: null,
                last_name: null,
                email: null,
                birthdate: null,
                data_protection: false,
                slug: this.$route.params.campaign
            },
            sent: false,
            errors: false,
            error: false,
            exists: false,
            loading: null,
            verified: false,
            verifiedFailed: false,
            campaign: null,
            alreadyRegistered: null
        }
    },
    methods: {
        getCampaign() {
            let campaign = this.$route.params.campaign

            window.axios.get(`/campaigns`, {
                params: {
                    slug: campaign
                }
            })
                .then(response => {
                    this.campaign = response.data
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        this.$router.push({name: 'not-found'})
                    }
                })
        },

        verifySubscriber() {
            window.axios.post(`/campaigns/subscribers/verify`, {
                verify_hash: this.$route.params.verify
            })
                .then(() => {
                    this.verified = true
                })
                .catch(error => {
                    if (error.response.status === 410) {
                        this.verifiedFailed = true
                    }
                })
        },
    },
    mounted() {
        this.getCampaign()

        if (this.$route.params.verify) {
            this.verifySubscriber()
        }
    }
}
</script>

<style scoped>

</style>