<template>
    <div class="flex justify-end h-12 w-screen px-4">
        <router-link to="/privacy" class="mr-4">Datenschutz</router-link>
        <router-link to="/impressum">Impressum</router-link>
    </div>
</template>

<script>
export default {
    name: "MrFooter"
}
</script>

<style scoped>

</style>