<template>
    <div>
        <div class="flex items-center flex-col p-4">
            <img src="/images/missyrockz_logo.png" alt="Logo" class="w-24 mb-4">

            <h1 v-if="!sent" class="text-missyPink text-5xl md:text-6xl mb-10 text-bodoni-moda">OMG! Heel'z FOR FREE? 😱</h1>

            <img v-if="!sent && !verified" src="/images/Totally_Rockz.jpg" class="mb-8 md:w-2/4" alt="Gewinnspiel">

            <img v-if="!sent" src="/images/octicons_heart.png" class="w-10 mb-8" alt="Heart">

            <div v-if="sent" class="flex items-center flex-col p-4">
                <div
                    class="p-4 shadow-2xl bg-white w-96 mb-16">
                    <div class="flex items-center flex-col border border-gray-400 text-center p-10">
                        <img src="/images/octicons_heart.png" class="mb-8 w-6" alt="Heart">
                        <h3 class="text-8xl text-missyPink mb-8">YES!</h3>
                        <p class="text-roboto-thin w-44">You can really ROCKZ!<br>Nur noch deine Email Adresse bestätigen
                            und fertig.</p>
                    </div>
                </div>
            </div>

            <p v-if="$route.params.verify && verified" class="text-2xl text-roboto w-full md:w-1/2 text-center">
                ROCKZ! Danke, dass du deine E-Mail-Adresse bestätigt hast. Somit bist du automatisch im Lostopf drin. MISSY ROCKZ wünscht dir viel Glück!
                <br><br>
                Your MISSY 💋
            </p>

            <p v-if="$route.params.verify && verifiedFailed" class="text-2xl text-roboto w-full md:w-1/2 text-center">
                Oh no! Leider ist der Link zur Verifizierung abgelaufen :(
                Aber hey, MISSY gibt dir eine zweite Chance, es erneut zu versuchen. Klicke dazu einfach auf folgenden
                Link:
                Ready, steady, ROCKZ!
                <br><br>
                <router-link to="/heel-z-for-free"
                             class="birthday-button text-white px-16 py-2 text-roboto text-sm mb-20">
                    REGISTRIERE DICH NEU
                </router-link>
            </p>

            <p v-if="exists" class="text-2xl text-roboto w-full md:w-1/2 text-center">
                Du hast dich bereits registriert. Die Gewinnerbekanntgabe erfolgt am 01.02.22. Good Luck ROCKZ FAN!;)
            </p>

            <div v-if="!$route.params.verify && !exists && !sent" class="flex items-center flex-col">
                <p class="text-lg text-roboto text-center w-60 md:w-96 mb-16">
                    <strong>
                        Richtig gehört! Registriere dich jetzt mit deiner E-Mail-Adresse und hüpfe automatisch in den Lostopf. Vergiss dabei nicht, diese noch einmal zu bestätigen.;)
                        <br><br>
                        Good Luck!:)
                    </strong>
                    <br><br>
                    <small>(Bis zum 31.01.2022 hast du die Chance Heel'z for free zu gewinnen. Wenn du einer der glücklichen Gewinner bist, benachrichtigen wir dich per Mail. Stay tuned!)</small>
                </p>

                <form method="post" @submit.prevent="addToContest"
                      class="flex justify-center items-center flex-col w-full md:w-2/4">

                    <div class="mb-4 w-full px-4 md:w-96">
                        <label>
                            <input name="email"
                                   type="email"
                                   class="w-full bg-inputBg text-roboto outline-none p-4 rounded-md text-center"
                                   placeholder="Deine E-Mail Adresse"
                                   v-model="form.email"
                                   required>
                        </label>
                        <small v-if="errors && errors.email" class="text-red-500 text-sm">{{ errors.email[0] }}</small>
                    </div>

                    <div class="mb-8 w-full px-4">

                        <label class="block left-label text-roboto-thin">
                            <input type="checkbox" name="data_protection" v-model="form.data_protection">
                            Hiermit erklärst du dich als Nutzer damit einverstanden, dass deine persönlichen Daten von
                            uns als Betreiber in elektronischer Form gespeichert werden – siehe
                            <router-link :to="{name: 'privacy'}" class="text-missyPink">Datenschutzerklärung
                            </router-link>
                            . Du stimmst zu, dass die im Rahmen der Registrierung erhobenen Daten und die weiteren von
                            dir eingegebenen Daten in einer Datenbank gespeichert werden – siehe
                            <router-link :to="{name: 'privacy'}" class="text-missyPink">Datenschutzerklärung
                            </router-link>
                            . Es erfolgt keine Datenspeicherung und Datennutzung ohne ein an anderer Stelle von dir
                            ausdrücklich eingeholtes Einverständnis.
                        </label>

                    </div>

                    <div class="flex">
                        <button type="submit"
                                v-if="!loading"
                                :class="{'birthday-button-not': !form.data_protection || errors}"
                                class="birthday-button text-white px-16 py-2 text-roboto text-sm mb-8"
                                :disabled="!form.data_protection">
                            ABSENDEN
                        </button>

                        <div v-else class="lds-facebook">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </form>
            </div>

            <span v-if="!sent && !verifiedFailed && !verified" class="mb-8">-- oder --</span>

            <a href="https://www.missy-rockz.com/heelz-for-free/"
               class="birthday-button text-white px-16 py-2 text-roboto text-sm mb-20"
               :class="{'mt-8': verified || verifiedFailed}">
                SHOP NOW
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeelzContest",
    props: {
        campaign: Object,
        verified: {
            type: Boolean,
            default: false,
        },
        verifiedFailed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            form: {
                email: null,
                data_protection: false,
                slug: this.$route.params.campaign
            },
            sent: false,
            errors: false,
            error: false,
            exists: false,
            loading: null,
        }
    },
    methods: {
        addToContest() {
            this.loading = true

            window.axios.post(`/campaigns/subscribers/subscribe`, this.form)
                .then(response => {
                    this.sent = response.data
                })
                .catch(error => {
                    console.log(error.response)
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors
                    } else if (error.response.status === 409) {
                        this.exists = true
                    }
                })
        },
    },
}
</script>

<style scoped>

</style>